import React, {useState} from 'react';
import '../../css/Login.css'
import '../../css/button.css'
import {useNavigate} from "react-router-dom";
import axios from "axios";


const LoginComp = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://www.iacdc.tau.ac.il/login/auth",{username, password});
                 // Assuming the token is in response.data.token
              const token = response.data.token;
              console.log('You are: ', response.data);
              // Store the token in localStorage
              localStorage.setItem('token', token);
              const user = response.data.username;
              localStorage.setItem('user', user);

              // Redirect to the protected route
              navigate('/admin_only');
        } catch (error) {
            setError('Invalid email or password');
        }
    };



  return (
    <div style={{marginTop:"75px", marginBottom:'30px'}}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:
          &nbsp;
          <input
              id={"username"}
              name={'username'}
              autoFocus={true}
              type="text"
              value={username}
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
          /></label>
        </div>
        <div style={{marginTop:"20px"}}>
          <label>Password:
          &nbsp;
          <input
              id={"password"}
              name={'password'}
              type="password"
              value={password}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
          /></label>
        </div>
        <button className={'button'} style={{marginTop:"20px"}} type="submit">
            Login
      </button>
      {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default LoginComp;