import React from 'react';
import '../../css/ReplyModal.css'

const ReplyModal = ({ show, handleClose, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="content">
                {children}
                <button className={'button'} onClick={handleClose}>Close</button>
            </section>
        </div>
    );
};

export default ReplyModal;
