import "../../css/button.css"
import NewDropdowns from "../../services/NewDropdowns";

const NewDataset = () => {
return (
        <div style={{marginTop: "75px", marginLeft: '25px', marginBottom: '30px'}}>
            <NewDropdowns/>
        </div>
    )
}

export default NewDataset;

