import React, {useEffect, useState} from 'react';
import '../css/NewsTicker.css'
import Utils from "../utils/Utils";
import {Link} from "react-router-dom";


const NewsTicker = () => {
    const [newsItems, setNewsItems] = useState([])
    useEffect(() => {
        const getDirs = async () => {
            let resp = await Utils.getAllDirs()
            let dirData = resp.data;
            // eslint-disable-next-line no-unused-expressions,array-callback-return
             setNewsItems(dirData)
            return dirData
        }
        getDirs()
    }, [])

  return (
      <div style={{marginBottom: "25px"}}>
          <h3>Available datasets:</h3>
          <br/>
          <div className="news-ticker">
              <ul>
                  {newsItems.map(item => {
                          return (<li key={item.id}>
                              <Link to={'/' + item.name}>{item.title}</Link>
                              </li>)
                      }
                  )}
              </ul>
          </div>
      </div>
  );
};

export default NewsTicker;
