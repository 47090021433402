import React, {useState, useEffect} from 'react';
import Utils from "../../utils/Utils";
import {useNavigate} from "react-router-dom";
import '../../css/button.css'

const PageEdit = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const token = localStorage.getItem("token");
    const [editableRows, setEditableRows] = useState([]);
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        const getDirToEdit = async () => {
            let response = await Utils.getAllDirs()
            const rawData = response.data;
            let dirsData = []
            rawData.forEach((item) => {
                dirsData.push({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    links: item.links,
                    title: item.title
                });
            })
            setData(dirsData)
        }
        getDirToEdit()
    }, [])

    const saveEdit = async () => {
        console.log(currentData);
        await Utils.editPage(currentData, token)
        navigate('/admin_only')
    }
    const handleCheckboxChange = (id) => {
        if (editableRows.includes(id)) {
          setEditableRows(editableRows.filter((rowId) => rowId !== id));
        } else {
          setEditableRows([...editableRows, id]);
        }
      };
    const handleInputChange = (id, column, value) => {
        console.log({id, column, value});
        setCurrentData({id, column, value})
    };

  return (
      <div style={{marginTop: "75px", marginLeft: '25px', marginBottom: '30px'}}>
          <h2>Edit Page</h2>
          <br/>
          <br/>
          <div>
              <table style={{width: "95%", border: "1px solid black"}} className={'table'}>
                  <thead>
                  <tr>
                      <th style={{fontSize: "small"}}>Dir to edit</th>
                      <th style={{border: "1px solid black"}}>ID</th>
                      <th style={{border: "1px solid black"}}>title</th>
                      <th style={{border: "1px solid black"}}>Name</th>
                      <th style={{border: "1px solid black"}}>Description</th>
                      <th style={{border: "1px solid black"}}>Links</th>
                      <th style={{border: "1px solid black"}}>Confirm/Delete</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                      data.map((x) => {
                              return (<tr key={x.id}>
                                      <td>
                                          <input
                                              id={`choose ${x.id}`}
                                              name={`choose ${x.id}`}
                                              type="checkbox"
                                              checked={editableRows.includes(x.id)}
                                              onChange={() => {
                                                  handleCheckboxChange(x.id);
                                              }}
                                          />
                                      </td>
                                      <td style={{border: "1px solid black"}}>{x.id}</td>
                                      {
                                          editableRows.includes(x.id) ? (
                                                  <td style={{width: '175px'}}>
                                                  <textarea style={{height: '100px', width: '95%'}} placeholder={x.title} onChange={(e) => {
                                                      handleInputChange(x.id, 'title', e.target.value)
                                                  }
                                                  }/>
                                                  </td>)
                                              :
                                              <td style={{padding: "5px", border: "1px solid black"}}>{x.title}</td>}
                                      <td style={{padding: "5px", border: "1px solid black"}}>{x.name}</td>
                                      {
                                          editableRows.includes(x.id) ? (
                                                  <td style={{border: "1px solid black", padding: "5px"}}>
                                                  <textarea style={{height: '200px', width: '95%'}} placeholder={x.description}
                                                            onChange={(e) => {
                                                                handleInputChange(x.id, 'description', e.target.value)
                                                            }}/>
                                                  </td>)
                                              :
                                              <td style={{
                                                  border: "1px solid black",
                                                  padding: "5px"
                                              }}>{x.description}</td>}
                                      {
                                          editableRows.includes(x.id) ? (
                                                  <td style={{border: "1px solid black", padding: "5px"}}>
                                                  <textarea style={{height: '200px', width: '95%'}} placeholder={x.links}
                                                            onChange={(e) => {
                                                                handleInputChange(x.id, 'links', e.target.value)
                                                            }}/>
                                                  </td>)
                                              :
                                              <td style={{padding: "5px", border: "1px solid black"}}>{x.links}</td>}
                                      {
                                          editableRows.includes(x.id) ? (
                                                  <td style={{border: "1px solid black", padding: "5px"}}>
                                                      <button className={'button'} onClick={() => {
                                                          localStorage.removeItem(x.name);
                                                          saveEdit()
                                                      }}>save
                                                      </button>
                                                      &nbsp;
                                                      <button className={'button'} onClick={() => {
                                                          navigate('/admin_only')
                                                      }}>Delete
                                                      </button>
                                                  </td>)
                                              :
                                              <td style={{border: "1px solid black", padding: "5px"}}></td>
                                      }
                                  </tr>
                              )
                          }
                      )}
                  </tbody>
              </table>
          </div>
      </div>
  )
}

export default PageEdit