import React from 'react';
import '../../css/Footer.css';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4' style={{ height: '150px' }}>
                  <p className='text-uppercase fw-bold mb-4'>
                      <a href={"/"} style={{
                          textDecoration: "none",
                          fontSize: "14px",
                          color: "black"
                        }}>Home
                      </a>
                      <br/>
                      <a href={"/about"} style={{
                          textDecoration:"none",
                          fontSize:"14px",
                          color:"black"
                      }}>About
                      </a>
                      <br/>
                      <a href={"/categories"} style={{
                          textDecoration:"none",
                          fontSize:"14px",
                          color:"black"
                      }}>Data by Category
                      </a>
                      <br/>
                      <a href={"/data-requests"} style={{
                          textDecoration:"none",
                          fontSize:"14px",
                          color:"black"
                      }}>Data Requests
                      </a>
                      </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                  <p className='text-uppercase fw-bold mb-4'>
                      <a href={"/data-access"} style={{textDecoration:"none", fontSize:"14px", color:"black"}}> Datasets </a>
                      <br/>
                      <a href={"/batch-downloads"} style={{textDecoration:"none", fontSize:"14px", color:"black"}}> Batch from the IACDC </a>
                      <br/>
                      <a href={"/research-repository"} style={{textDecoration:"none", fontSize:"14px", color:"black"}}> Research Data
                      Repository </a>
                      <br/>
                      <a href={"/Online-ressources"} style={{textDecoration:"none", fontSize:"14px", color:"black"}}> Other Online Resaerch </a>
                  </p>
              </MDBCol>
              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                   <div align={"left"}>Contact:
                       <br/>
                      <MDBIcon icon="home" className="me-2" />
                      <strong>Tel Aviv University</strong><br/>
                      <div style={{marginLeft:"10px"}}><a href={"contact"}>Contact</a></div>
                   </div>
              </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
        <div className='text-center p-4' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
            Copyright 2023 Israeli Atmospheric and Climatic Data Center | All Rights Reserved
            <h6 align={"left"}>Developed by <a href={"https://www.linkedin.com/in/jeremyliban/"}
                            target={"_blank"}
                            rel={"noreferrer"}>J.R.L</a>
            </h6>
        </div>
    </MDBFooter>
);
}

export default Footer