import React from 'react';
import Utils from "../../utils/Utils";

const WgetDownload = (props) => {
    const filesToDownload = []
    const navigate = () =>  window.location.reload()
    const handleDownload = async () => {
        try {
            // eslint-disable-next-line array-callback-return
            props.selectedData.map(x => {
                if (x.type === 'wget')
                {
                    console.log(`${x.name} is not downloadable`);
                }
                else
                {
                    filesToDownload.push(x.path)
                }
            })
            console.log("data to download: " + filesToDownload)
            const response = await Utils.multiDownloads(filesToDownload)
            // Create a URL for the zip blob
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${props.name}.zip`); // Set the file name
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL to free up memory
            window.URL.revokeObjectURL(url);
            window.location.reload()
        }
        catch (error) {
            console.error('Error downloading the zip file:', error);
        }
    };


    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href='#'
               onClick={() => {
                    handleDownload().then(
                        navigate
                        )
                }}
                    >
                {props.children}
            </a>
        </div>
    );
}

export default WgetDownload;