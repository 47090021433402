import React, {useEffect, useState} from 'react';
import '../../css/2DivsSBS.css'
import {useNavigate} from "react-router-dom";
import Utils from "../../utils/Utils";
import Modal from 'react-modal';


function RequestFormComp() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  const navigate = useNavigate()
  const [requestFormData, setRequestFormData] = useState({
    name: '',
    email: '',
    dataset: '',
    variable: '',
    start_date: '',
    end_date: '',
    domain: '',
    time_resolution: '',
    spatial_resolution: '',
    level_type: '',
    level: '',
    additional_info: ''
  });
  const time = new Date().toLocaleString()
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRequestFormData({
      ...requestFormData,
      [name]: value,
      time
    });

  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(requestFormData);
    Utils.dataRequest(requestFormData)
    .then((response) => {
      setIsModalOpen(true);
        setTimeout(() => {
          setIsModalOpen(false);
          navigate('/');},8000);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  };
  return (
    <div style={{marginTop:"75px"}}>
      <h1>Data Request</h1>
      <form onSubmit={handleSubmit}>
        <div style={{display: "inline-block"}}>
          <div align={"left"} style={{marginBottom: "15px", marginTop: "50px"}}>
            <label style={{marginRight: "25px"}}>name:
              &nbsp;
              <input style={{width: "250px"}}
                     id={'name'}
                     type="text"
                     name="name"
                     value={requestFormData.name}
                     onChange={handleInputChange}
                     required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label style={{marginRight: "25px"}}>email:
              &nbsp;
              <input style={{width: "250px"}}
                     id={'email'}
                     type="email"
                     name="email"
                     value={requestFormData.email}
                     onChange={handleInputChange}
                     required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label style={{marginRight: "25px"}}>dataset:
              &nbsp;
              <input style={{width: "250px"}}
                     id={'dataset'}
                     type="text"
                     name="dataset"
                     value={requestFormData.dataset}
                     onChange={handleInputChange}
                     required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label style={{marginRight: "25px"}}>variable:
              &nbsp;
              <input
                  style={{width: "250px"}}
                  id={'variable'}
                  type="text"
                  name="variable"
                  value={requestFormData.variable}
                  onChange={handleInputChange}
                  required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label style={{marginRight: "25px"}}><strong>time range:</strong>
              &nbsp;
              <p></p>
              Start date: &nbsp;<input
                  id={'start_date'}
                  type="date"
                  name="start_date"
                  value={requestFormData.start_date}
                  onChange={handleInputChange}
                  required
              />
              <br/>
              End Date: &nbsp;<input
                  style={{marginTop: "10px"}}
                  id={'end_date'}
                  type="date"
                  name="end_date"
                  value={requestFormData.end_date}
                  onChange={handleInputChange}
                  required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label>domain: &nbsp;
              <select
                  id={'domain'}
                  name="domain"
                  value={requestFormData.domain}
                  onChange={handleInputChange}
                  required
              >
                <option value="none" selected>Select requested Domain</option>
                <option value={"north"}>North</option>
                <option value={"south"}>South</option>
                <option value={"east"}>East</option>
                <option value={"west"}>West</option>
              </select></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label>time resolution: &nbsp;
              <select
                  id={'time_resolution'}
                  name="time_resolution"
                  value={requestFormData.time_resolution}
                  onChange={handleInputChange}
                  required
              >
                <option value="none" selected>Select requested time resolution</option>
                <option value={"1min"}>1 Minute</option>
                <option value={"10min"}>10 Minute</option>
                <option value={"1hour"}>1 Hour</option>
                <option value={"3hour"}>3 Hours</option>
                <option value={"6hour"}>6 Hours</option>
                <option value={"daily"}>Daily</option>
                <option value={"monthly"}>Monthly</option>
                <option value={"annual"}>Annual</option>
              </select></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label>spatial resolution: &nbsp;
              <input
                  style={{width: "250px"}}
                  id={'spatial_resolution'}
                  type="text"
                  name="spatial_resolution"
                  value={requestFormData.spatial_resolution}
                  onChange={handleInputChange}
                  required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label>level type: &nbsp;
              <select
                  id={'level_type'}
                  name="level_type"
                  value={requestFormData.level_type}
                  onChange={handleInputChange}
                  required
              >
                <option value="none" selected>Select requested Level Type</option>
                <option value={"model"}>Model Levels</option>
                <option value={"surface"}>Surface</option>
                <option value={"pressure"}>Pressure Levels</option>
                <option value={"temps"}>Potential Temperature Levels</option>
              </select></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label style={{marginRight: "25px"}}>level:
              &nbsp;
              <input
                  style={{width: "250px"}}
                  id={'level'}
                  type="text"
                  name="level"
                  value={requestFormData.level}
                  onChange={handleInputChange}
                  required
              /></label>
          </div>
          <div align={"left"} style={{marginBottom: "15px"}}>
            <label><strong>additional info:</strong> &nbsp;
              <textarea style={{width: "400px", height: "180px"}}
                        id={'additional_info'}
                        name="additional_info"
                        value={requestFormData.additional_info}
                        onChange={handleInputChange}
              ></textarea></label>
          </div>
          <button className={'button'} type="submit">Submit</button>
        </div>
        <div style={{display: "none"}}>
        </div>
      </form>
      <Modal
          style={{
            overlay: {
              position: 'fixed',
              top: "75px",
              left: "25%",
              right: "25%",
              bottom: "25%",
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              borderRadius: '10px'
            },
            content: {
              position: 'absolute',
              top: '40px',
              left: '40px',
              right: '40px',
              bottom: '40px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px'
            }
            }}
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Request Submitted"
      >
          <h2>Request Submitted</h2>
          <p>Your form has been submitted successfully.</p>
          <button className={'button'} onClick={() => {
            setIsModalOpen(false);
            navigate('/')
          }}>Close</button>
      </Modal>
    </div>
  );
}

export default RequestFormComp;


