import axios from "axios";
const baseURL = "https://www.iacdc.tau.ac.il"


const getAllDirs = async () => await axios.get(`${baseURL}/api/main`);

const getSubDirs = async (name) => await axios.get(`${baseURL}/api/sub/${name}/`)

const submission = async (form) => await axios.post(`${baseURL}/submit`, form, {headers: {'Content-Type': 'application/json'}})

const dataRequest = async (form) => await axios.post(`${baseURL}/request_data`, form, {headers: {'Content-Type': 'application/json'}})

const login = async (form) => await axios.post(`${baseURL}/login/auth`, form, {headers: {'Content-Type': 'application/json'}})

const logout = async () => await axios.post(`${baseURL}/logout`)

const showSubmits = async (token) => await axios.get(`${baseURL}/admin_only/submits`, {headers: {"Authorization": `Bearer ${token}`}})

const showRequests = async (token) => await axios.get(`${baseURL}/admin_only/requests`, {headers: {"Authorization": `Bearer ${token}`}})

const reply = async (form, token) => await axios.post(`${baseURL}/admin_only/reply`, form, {headers: {"Authorization": `Bearer ${token}`}})

const download = async (file) => await axios.get(`${baseURL}/links/${file}`, {responseType: 'blob'})

const multiDownloads = async (selected_data) => await axios.post(`${baseURL}/download-selected`, selected_data, {responseType: 'blob'})

const editPage = async (data, token) => await axios.post(`${baseURL}/admin_only/edit`, data, {headers: {"Authorization": `Bearer ${token}`}})

const newDataset = async (token) => await axios.get(`${baseURL}/admin_only/new`,  {headers: {"Authorization": `Bearer ${token}`}})

const symlink = async (dataset, token) => await axios.post(`${baseURL}/admin_only/symlink`, dataset, {headers: {"Authorization": `Bearer ${token}`}})

// eslint-disable-next-line import/no-anonymous-default-export
export default {getAllDirs,
                getSubDirs,
                submission,
                dataRequest,
                login,
                logout,
                showSubmits,
                showRequests,
                download,
                multiDownloads,
                editPage,
                newDataset,
                symlink,
                reply
                };