import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Utils from "../utils/Utils";
import FileDownload from "./downloads/FileDownload";
import WgetDownload from "./downloads/WgetDownload";

const PaginationTable = (props) => {
    let numOfRow = 0
    const [checkable, setCheckable] = useState(false);
    const [pagePath, setPagePath] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const pathname = window.location.pathname.slice(1)
    const [sub, setSub] = useState( pathname)
    const [subsData, setSubsData] = useState([])
    const [findData, setFindData] = useState('')
    const [parent, setParent] = useState('')
    const [isParent, setIsParent] = useState( false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedGif, setSelectedGif] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = subsData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(subsData.length / itemsPerPage);
    const nextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
    const prevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(props)
        let dirData = []
        const getData = () => {
            props.data.forEach((item) => {
                console.log('item', item);
                dirData.push(item);
            })

            // eslint-disable-next-line array-callback-return
            dirData.sort((a, b) => {
                if (a.type === "wget" && b.replied !== "wget") {
                    return -1;
                }
                if (b.type === "wget" && a.replied !== "wget") {
                    return -1;
                }
            })
            // eslint-disable-next-line array-callback-return
        dirData.sort((a, b) => {
            if (a.type === "wget" && b.type !== "wget") {
                return -1;
            }
            if (b.type === "wget" && a.type !== "wget") {
                return -1;
            }
        })
            // eslint-disable-next-line array-callback-return
            dirData.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
            })
            setSubsData(dirData)
        }

        getData()

    }, [props.data])
    const getSubSubs = async (dirPath) => {
        setPagePath(dirPath.slice(0, dirPath.lastIndexOf("/")))
        const dirData = await Utils.getSubDirs(dirPath)
        let data = dirData.data;
        let dirsData = []
        data.forEach((item) => {
            dirsData.push(item);
        })
        setSubsData(dirsData)
    }

    const downloadWget = () => {
        const dataForDownload = []
        subsData.forEach((item) => {
            if (item.type !== "wget") {
                console.log(item)
                dataForDownload.push(item)
            }
            else {
                console.log('This not downloadable:', item)
            }
        })
        setSelectedData(dataForDownload)
        return handleDownload()
    };

    const toggleOverlay = (gif) => {
        setShowOverlay(!showOverlay);
        setSelectedGif(gif);
    };

    const back = () => {
        setParent(pagePath)
        getSubSubs(pagePath)
        if (pagePath === sub[0]) {
            setIsParent(!isParent)
        } else {
            setIsParent(true)
        }
    }
    const FindData = async () => {
        const searchData = subsData.filter(row =>
            row.name.includes(findData))
        setSubsData(searchData)
    }

    const handleRadioChange = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // If checked, add data to the array
            setSelectedData(prevData => [...prevData, value]);

        } else {
            // If unchecked, remove data from the array
            setSelectedData(prevData => prevData.filter(item => item !== value));
        }
    };
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files).map(file => file.name);
        setSelectedData(files);
        return files
    };

    const handleDownload = async () => {
        try {
            console.log("data to download: ", JSON.stringify(selectedData))
            const response = await Utils.multiDownloads(selectedData)
            // Create a URL for the zip blob
            const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'files.zip'); // Set the file name
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Revoke the object URL to free up memory
            window.URL.revokeObjectURL(url);
            window.location.reload()
        } catch (error) {
            console.error('Error downloading the zip file:', error);
        }
    };

  return (
      <div>
          <>
              {isParent && ( <>
                  <div align={"center"}>
                      <button className={'button'} onClick={() => {
                          back()
                      }}>
                          Back
                      </button>
                  </div>
              </>
              )}
          </>
          <div align={"left"}>
              {
                  isParent ?
                      <h6 style={{
                          backgroundColor: "#e5e7eb",
                          borderRadius: "10px",
                          height:'fit-content',
                          padding: "2px",
                          width: "fit-content"
                      }}>{parent}</h6>
                      :
                      <h6 style={{
                          backgroundColor: "#e5e7eb",
                          borderRadius: "10px",
                          padding: "2px",
                          height:'fit-content',
                          width: "fit-content"
                      }}>{}</h6>
              }
          </div>
          <div align={"center"}>
              <input type={"text"}
                     id={"findData"}
                     name={"findData"}
                     style={{marginLeft: "33%", borderRadius: "7px"}}
                     placeholder={"search specific data"}
                     onChange={e => setFindData(e.target.value)}/>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button className={'button'} onClick={() => FindData()}>Find</button>
          </div>
          <br/>
          <button onClick={handleDownload}>Download selected</button>
              <table style={{width: "55%"}} className={'table'}>
                  <thead>
                  <tr>
                      <th style={{fontSize: "small"}}>
                          <input type={"checkbox"}
                                 name={"multiple choice"}
                                 onClick={() => {
                                     setCheckable(!checkable)
                                 }}/> multiple choice
                      </th>
                      <th>ID</th>
                      <th>type</th>
                      <th>Drawer/File</th>
                      <th>Size</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                      // eslint-disable-next-line array-callback-return
                      currentItems.map((x, index) => {
                          if (x['size'] !== 'total') {
                              numOfRow = numOfRow + 1
                              if (x['type'] === 'dir') {
                                  return (<tr key={x.id}>
                                      <td>{checkable &&
                                          <input type={"checkbox"} name={x.name}
                                                 value={x.path}
                                                 onChange={handleFileChange()} disabled={true}/>}</td>
                                      <td>{numOfRow}</td>
                                      <td>{x.type}</td>
                                      <td>
                                          <div key={index}>
                                              <Link to={'/' + sub + '/' + x.name}
                                                    onClick={() => {
                                                        setSub(`${props.mainPath}/${x.name}`)
                                                        getSubSubs(`${sub}/${x.name}`);
                                                        setParent(`${sub}/${x.name}`);
                                                        setIsParent(true)
                                                    }}>{x.name}
                                              </Link>
                                          </div>
                                      </td>
                                      <td>{x.size}</td>
                                  </tr>)
                              } else if (x['type'] === 'gif') {
                                  return (
                                      <tr key={x.id}>
                                          <td>{checkable &&
                                              <input type={"checkbox"} name={x.name}
                                                     value={x.path}
                                                     onChange={handleRadioChange} disabled={true}/>}</td>
                                          <td>{numOfRow}</td>
                                          <td>{x.type}</td>
                                          <td>
                                              <div onClick={() => {
                                                  toggleOverlay(x.path)
                                              }}>{x.name}</div>
                                          </td>
                                          <td>{x.size}</td>
                                      </tr>)
                              } else if (x['type'] === 'wget' && !isParent) {
                                  return (<tr key={x.id}>
                                      <td>{checkable &&
                                          <input type={"checkbox"} name={x.name}
                                                 value={x.path}
                                                 onChange={handleRadioChange} disabled={true}/>}</td>
                                      <td>{numOfRow}</td>
                                      <td>{x.type}</td>
                                      <td>
                                          <WgetDownload selectedData={subsData} name={x.name}>{x.name}</WgetDownload>
                                      </td>
                                      <td>{x.size}</td>
                                  </tr>)
                              } else {
                                  return (<tr key={x.id}>
                                      <td>{checkable &&
                                          <input type={"checkbox"} name={x.name}
                                                 value={x.path}
                                                 onChange={
                                                     handleRadioChange
                                                 }/>}</td>
                                      <td>{numOfRow}</td>
                                      <td>{x.type}</td>
                                      <td>
                                          <FileDownload props={`${x.path}`} parent={parent}>{x.path.split('/').pop()}</FileDownload>
                                      </td>
                                      <td>{x.size}</td>
                                  </tr>)
                                  }
                              }
                          }
                      )
                  }
                  </tbody>
              </table>
          {showOverlay && (
              <div className="overlay"
                   onClick={() => {
                       toggleOverlay('')
                   }}>
                  <img src={selectedGif} alt="Animated GIF"/>
              </div>
          )}
          <div>
              <button onClick={prevPage} disabled={currentPage === 1}>
                  Prev
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={nextPage} disabled={currentPage === totalPages}>
                  Next
              </button>
          </div>
      </div>
  )
}

export default PaginationTable
