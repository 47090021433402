import React, {useEffect, useState} from 'react';
import Utils from "../../utils/Utils";
import '../../css/button.css'

const ReplyForm = ({ handleClose, name, email, submit_id, request_submit }) => {
    const token = localStorage.getItem("token");

    const [formData, setFormData] = useState({
        email: email || '',
        subject: `Regarding ${name} request from IACDC` || '',
        message: '',
        id: submit_id,
        table: request_submit
    });

    useEffect(() => {
        setFormData({
            email: email || '',
            subject: `Regarding ${name} request from IACDC` || '',
            message: '',
            id: submit_id,
            table: request_submit
        });
    }, [email, name, request_submit, submit_id]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (token) {
            try {
                const response = await Utils.reply(formData, token)
                console.log(response.data);
                alert('Email sent successfully');
                handleClose();
            } catch (error) {
                console.error(error);
                alert('Failed to send email');
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Email:
                    &nbsp;
                    <input style={{width:'250px'}} id={'email'} autoComplete={'email'} type="email" name="email" value={formData.email} onChange={handleChange} required/>
                </label>
            </div>
            <div style={{marginTop: "20px"}}>
                <label>Subject:
                    &nbsp;
                    <input style={{width: '350px'}} autoComplete={'name'} id={'subject'} type="text" name="subject"
                           value={formData.subject} onChange={handleChange} required/>
                </label>
            </div>
            <div style={{marginTop: "20px"}}>
                <label>Message:
                    &nbsp;
                    <textarea name="message" id={'message'} value={formData.message}
                              style={{width: '500px', height: '250px'}} onChange={handleChange} required></textarea>
                </label>
            </div>
            <br/>
            <button className={'button'} type="submit">Send Email</button>
        </form>
    );
};

export default ReplyForm;
