import Example from "./Accordeon";

function ItemListComp({ items }) {
  let cat = 0
  const index = cat + 1
  return (
    <ul>
      {items.map((item) => (
        <Example key={index} content={item} />
      ))}
    </ul>
  );
}

export default ItemListComp;
