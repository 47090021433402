import React from 'react';
import Utils from "../../utils/Utils";



const FileDownload = ({props, children}) => {
    const navigate = () =>  window.location.reload()
    const handleDownload = async () => {
            console.log('the path of the  file: ' + (props))
        if (props.includes('/var/www/public_links/')) {
            let props_path = props.split('/var/www/public_links/')[1]
            const response = await Utils.download(props_path)
            const blob = new Blob([response.data], {type: response.data.type});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = children
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
        else {
            const response = await Utils.download(props)
            const blob = new Blob([response.data], {type: response.data.type});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = children
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
        // eslint-disable-next-line no-restricted-globals
    }


    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href='#'
               onClick={() => {
                    handleDownload().then(
                        navigate
                        )
                }}
                    >
                {children}
            </a>
        </div>
    );
};

export default FileDownload;
