import {useEffect, useState} from "react";
import Utils from "../../utils/Utils";
import Tooltip from "../../services/Tooltip";

function DataAccessComp() {
    const [data, setData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0)
        const datasetNames = []
       const getDirs = async () => {
           let resp = await Utils.getAllDirs()
           const dirData = resp.data;
           // eslint-disable-next-line array-callback-return
           Object.entries(dirData).map(([key, value]) => {
               datasetNames.push(value)
           })
           setData(datasetNames)
       }
       getDirs()
    }, [])

    return (
        <div align={'left'} id={"dataAccess"} style={{marginTop: "70px"}}>
            <div>
                <h2>Datasets ready to download</h2>
            </div>
            <br/>
            <br/>
            <div>
                <ul>
                {
                    data.map((item, i) => {
                        console.log(item)
                        return (<li>
                                    <h5>{item.title}</h5>
                                    <Tooltip text={item.description}>
                                        <button key={i} className={'button'}><a href={`/${item.name}`}>{item.title}</a></button>
                                    </Tooltip>
                                </li>)
                    })
                }
                </ul>
            </div>
        </div>
    )
}

export default DataAccessComp;