// src/components/Header.js
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../../css/Header.css'
import Utils from "../../utils/Utils";
import logo from './transparentLogo.png';



function Header() {
    const [suberName, setSuberName] = useState([])
    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(true);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const [showSub, setShowSub] = useState(false)
    const showSubDropdown = () => {
        setShowSub(!showSub)
    }
    const hideSubDropdown = () => {
        setShowSub(false)
    }
   useEffect(() => {
       const subsname = []
       const getDirs = async () => {
           let resp = await Utils.getAllDirs()
           const dirData = resp.data;
           // eslint-disable-next-line array-callback-return
           Object.entries(dirData).map(([key, value]) => {
               subsname.push(value)
               localStorage.setItem(value.name, value.title)
           })
           setSuberName(subsname)
       }
       //localStorage.setItem('links', JSON.stringify({'/batch-downloads': 'Download Data in Batch from the IACDC', '/research-repository': 'Research Data Repository', '/Online-resources': 'Other Online Research', '/data-requests': 'Data Requests', '/israel-regional-data': 'East Mediterranean', '/categories': 'Data by Category', '/contact': 'Contact', '/about': 'About'}))
       getDirs()
   }, []);

  return (
      <header>
          <Navbar onMouseLeave={hideDropdown} fixed={"top"} bg="light" expand="lg" style={{marginBottom: "10px"}}>
              <Container>
                  <Navbar.Brand href="/"><img src={logo}
                                              alt={"Isreali Atmpspheric and Climatic Data Center Logo"}/></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                  <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto" justify variant="tabs" style={{width: '100%'}}>
                          <Nav.Link href={"/"}>Home</Nav.Link>
                          <Nav.Link href={"/about"}>About</Nav.Link>
                          <Nav.Link href={"/categories"}>Data by Category</Nav.Link>
                          <NavDropdown style={{borderRadius: "7px"}} id="basic-nav-dropdown" show={show}
                                       title="Data Access"
                                       onMouseEnter={showDropdown}>
                              <NavDropdown style={{borderRadius: "7px"}}
                                           id="collasible-nav-dropdown"
                                           title={"Datasets"}
                                           drop={"end"} show={showSub}
                                           onMouseEnter={showSubDropdown}
                                           onMouseLeave={hideSubDropdown}>
                                  <div>
                                      {
                                          suberName.map((x, index) => {
                                              return <div key={index}><NavDropdown.Item
                                                  href={"/" + x.name}
                                                  onClick={() => {
                                                      sessionStorage.setItem('title', x.title)
                                                  }}
                                                  style={{height: "30px"}}>
                                                  {x.title}
                                              </NavDropdown.Item>
                                              </div>
                                          })
                                      }

                                  </div>
                              </NavDropdown>
                              <NavDropdown.Divider/>
                              <NavDropdown.Item href={"/batch-downloads"}>Download Data in <br/>Batch from the
                                  IACDC</NavDropdown.Item>
                              <NavDropdown.Divider/>
                              <NavDropdown.Item href={"/research-repository"}>Research Data
                                  Repository</NavDropdown.Item>
                              <NavDropdown.Divider/>
                              <NavDropdown.Item href={"/Online-ressources"}>Other Online Resaerch</NavDropdown.Item>
                          </NavDropdown>
                          <Nav.Link href={"/contact"}>Contact</Nav.Link>
                          <Nav.Link href={"/login"}> Login</Nav.Link>
                      </Nav>
                  </Navbar.Collapse>
              </Container>
          </Navbar>
      </header>
  );
}

export default Header;


/*

const [showSub, setShowSub] = useState(false);
    const showSubDropdown = (e)=>{
        setShow(!show);
    }
    const hideSubDropdown = e => {
        setShow(false);
    }
 */