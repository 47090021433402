import React, {useEffect, useState} from "react";
import Utils from "../../utils/Utils";
import "../../css/button.css"
import {useNavigate} from "react-router-dom";
import ReplyModal from "../../services/modals/ReplyModal";
import ReplyForm from "./EmailReply";

const Requests = () => {
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem("token");
    const [requests, setRequests] = useState([])
    const [unRepliedRequests, setUnRepliedRequests] = useState([])
    useEffect(() => {
        let subs = []
        let newSubs = []
        const getSubmits = async () => {
            let resp = await Utils.showRequests(token)
            const req = resp.data
            // eslint-disable-next-line array-callback-return
            Object.entries(req).map(([key, value]) => {
                if (value.replied === false) {
                    newSubs.push(value)
                }
                else {
                    subs.push(value)
                }
                console.log(value)
            })
            // eslint-disable-next-line array-callback-return
            newSubs.sort((a, b) => {
                if (a.time > b.time) {
                    return -1;
                }
            })
            setUnRepliedRequests(newSubs)
            // eslint-disable-next-line array-callback-return
            subs.sort((a, b) => {
                if (a.time > b.time) {
                    return -1;
                }
            })
            setRequests(subs)

        }
        getSubmits()

    }, [token]);

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/admin_only')
    };
    const handleRowClick = (email, name, submit_id) => {
        setSelectedRow({ email, name, submit_id });
    };

        return (
            <div style={{marginBottom:'30px'}}>
                <div>
                    <h2 align={"center"}>Requests for Datasets</h2>
                </div>
                <div align={'left'} style={{paddingTop: "25px"}}>
                    <table style={{border: "1px solid black"}}>
                        <thead>
                        <tr>
                            <th style={{padding: "5px", border: "1px solid black"}}>id</th>
                            <th style={{border: "1px solid black"}}>Time</th>
                            <th style={{border: "1px solid black"}}>name</th>
                            <th style={{border: "1px solid black"}}>email</th>
                            <th style={{border: "1px solid black"}}>Dataset</th>
                            <th style={{border: "1px solid black"}}>Variable</th>
                            <th style={{border: "1px solid black"}}>Start date</th>
                            <th style={{border: "1px solid black"}}>End date</th>
                            <th style={{border: "1px solid black"}}>Domain</th>
                            <th style={{border: "1px solid black"}}>Time resolution</th>
                            <th style={{border: "1px solid black"}}>Spatial resolution</th>
                            <th style={{border: "1px solid black"}}>Level type</th>
                            <th style={{border: "1px solid black"}}>Level</th>
                            <th style={{border: "1px solid black"}}>Additional info</th>
                            <th style={{border: "1px solid black"}}>Date Created</th>
                            <th style={{border: "1px solid black"}}>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th colSpan={16}>Unread</th>
                        </tr>
                        {
                            // eslint-disable-next-line array-callback-return
                            unRepliedRequests.map((x, index) => {
                                    return (<tr style={{border: "1px solid black", fontWeight:x.replied ? 'normal' : 'bold'}} key={index}>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.id}</td>
                                            <td style={{padding: "5px"}}>{x.time}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.name}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}} onClick={() => {
                                                handleOpenModal()
                                                handleRowClick(x.email, x.name, x.id)
                                            }}>{x.email}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.dataset}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.variable}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.start_date}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.end_date}</td>
                                            <td style={{border: "1px solid black"}}>{x.domain}</td>
                                            <td style={{border: "1px solid black"}}>{x.time_resolution}</td>
                                            <td style={{border: "1px solid black"}}>{x.spatial_resolution}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.level_type}</td>
                                            <td style={{border: "1px solid black"}}>{x.level}</td>
                                            <td style={{border: "1px solid black"}}>{x.additional_info}</td>
                                            <td style={{border: "1px solid black"}}>{x.time}</td>
                                            <td style={{border: "1px solid black"}}>{x.replied}</td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        <tr>
                            <th colSpan={16}>Read</th>
                        </tr>
                        {
                            // eslint-disable-next-line array-callback-return
                            requests.map((x, index) => {
                                    return (<tr style={{border: "1px solid black", fontWeight:x.replied ? 'normal' : 'bold'}} key={index}>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.id}</td>
                                            <td style={{padding: "5px"}}>{x.time}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.name}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}} onClick={() => {
                                                handleOpenModal()
                                                handleRowClick(x.email, x.name, x.id)
                                            }}>{x.email}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.dataset}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.variable}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.start_date}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.end_date}</td>
                                            <td style={{border: "1px solid black"}}>{x.domain}</td>
                                            <td style={{border: "1px solid black"}}>{x.time_resolution}</td>
                                            <td style={{border: "1px solid black"}}>{x.spatial_resolution}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.level_type}</td>
                                            <td style={{border: "1px solid black"}}>{x.level}</td>
                                            <td style={{border: "1px solid black"}}>{x.additional_info}</td>
                                            <td style={{border: "1px solid black"}}>{x.time}</td>
                                            <td style={{border: "1px solid black"}}>{x.replied}</td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                    {selectedRow &&( <ReplyModal show={showModal} handleClose={handleCloseModal}>
                                            <ReplyForm handleClose={handleCloseModal} request_submit={'requests'} submit_id={selectedRow.submit_id} email={selectedRow.email} name={selectedRow.name} />
                                    </ReplyModal>
                    )}
                </div>
            </div>
        );
    }

export default Requests