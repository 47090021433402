import './App.css';
import MainPage from "./pages/MainPage";

function App() {
  return (
    <div className="App">
        <div><MainPage/></div>
    </div>
  );
}

export default App;
