import ContactFormComp from "./ContactForm";
import {useEffect} from "react";

function ContactComp() {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{marginTop:"75px", marginBottom:'30px'}}>
                <div className={"flex-child-element:first-child"} align={"left"}
                 style={{float: "right", width: "50%", marginTop: "15px", marginRight: "35px"}}>
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15172.826890802764!2d34.802723353095054!3d32.11350244220727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4965df760bfb%3A0x59bd1a3ce25e6def!2sTel%20Aviv%20University!5e0!3m2!1sen!2sil!4v1695663114814!5m2!1sen!2sil"
                        width="650"
                        height="400"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <div align={'left'} style={{inlineSize: "left", width: "50%", marginRight: "35px"}}>
                <ContactFormComp />
            </div>
        </div>
    )
}
export default ContactComp