import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import '../../css/button.css'
import {Accordion} from "react-bootstrap";

function RepComp () {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    const accordionData = {
        title: "Aerosols & Rainfall Station Data - Alpert, Shafir and Elhacham",
        content: <div>
            <ul>
                <li>The data consists of 8 Israeli stations and 2 US California stations measuring rainfall (mm) and aerosols PM10 and PM2.5 (micrograms/m^3)</li>
                <li>Temporal resolution: daily/60min/30min/10min for winters between 1/11/2014-28/2/2015</li>
                <li>Israeli stations: Carmiel, Nesher, Nve Shaanan, Yad Avner, Givaatayim, Modiin, Ashqelon, Beer Sheva</li>
                <li>US stations: Livermore-Rincon Ave., Sebastopol</li>
            </ul>
        </div>
    }
    return (
        <div>
            <div align={"left"} className={"flex-child-element:first-child"}
                 style={{float: "right", width: "27%", marginTop: "60px", marginRight: "35px"}}>
                <h6>Details on how to download from the IACDC in batch can be found on:</h6>
                <br/>
                <Link to={"/batch-downloads"}>
                    <button className={'button'} style={{marginBottom: "10px"}}>How to Download Data in Batch from the IACDC</button>
                </Link><br/><br/>
                <p>For any other questions or issues please get in touch:</p>
                <Link to={"/contact"}>
                    <button className={'button'} style={{marginBottom: "10px"}}>Contact Us</button>
                </Link><br/>

            </div>
            <div className={"flex-child-element"} align={"left"}
                 style={{marginTop: "75px", marginLeft: "25px", width: "55%", marginBottom:'30px'}}>
                <h2>Research Data Repository</h2>
                <br/>
                <br/>
                <br/>
                <p>The IACDC also serves as a data repository, which hosts results from up-to-date research in order to support scientific transparency and to allow public access to the data.</p>
                <p>The data is uploaded by request. To save your results at the repository, please contact us and we will be happy to help.</p>
                <p style={{marginBottom:"100px"}}>To download all data available for a specific study, click on its wget/rar file. For using wget, follow our instructions on <Link to={"/batch-downloads"}>downloading data in batch from the IACDC</Link>.</p>
                <hr/>
                <h5>Available Data</h5>
                <React.Fragment>
                     <Accordion>
                         <Accordion.Item eventKey='0'>
                             <Accordion.Header>{accordionData.title}</Accordion.Header>
                         <Accordion.Body>
                             {accordionData.content}
                         </Accordion.Body>
                         </Accordion.Item>
                     </Accordion>
                </React.Fragment>
            </div>
        </div>
    )
}

export default RepComp