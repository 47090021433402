import {Link} from "react-router-dom";
import {useEffect} from "react";

function BatchComp () {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div align={"left"} style={{marginTop:"75px", marginLeft:"25px", width:"55%", marginBottom:'30px'}}>
                <h2>Batch Downloads from the IACDC</h2>
                <br/>
                <h5>Downloading in batch from the IACDC is done using wget. To download in batch from the IACDC please follow the instructions below:</h5>
                <br/>
                <h5>To download all files in a directory:</h5>
                <ol>
                    <li>In the download section of the relevant dataset, go to the directory containing the files you wish to download.</li>
                    <li>Download the text file at the top of the directory called 1wget_8756 (note the numbers at the end change in each directory).</li>
                    <li>From your command line execute the following command:
                        <p>wget –cN -i 1wget_8756</p>
                    </li>
                    <li>The download of all files in that directory will follow.</li>
                </ol>
                <br/>
                <h5>Should you not require all the files in the directory:</h5>
                <ol>
                    <li>In the download section of the relevant dataset, go to the directory containing the files you wish to download.</li>
                    <li>Download the text file at the top of the directory called 1wget_8756 (note the numbers at the end change in each directory).</li>
                    <li>Manually edit this file to contain only the download links you wish you download, i.e. delete the links for files that are not of interest.</li>
                    <li>From your command line execute the following command:
                        <p>wget –cN -i 1wget_8756</p>
                    </li>
                    <li>The download of the relevant files in that directory will follow.</li>
                </ol>
                <h5>wget options:</h5>
                <br/>
                <p>Downloading using wget provides a large number of options depending on your needs. The following options we have found most useful when downloading data from the IACDC:</p>
                <ul>
                    <li>‘-c’ or ‘–continue’: Continue getting a partially-downloaded file. This is useful when you want to finish a previously started download.</li>
                    <li>‘-N’ or ‘–timestamping’: Turns on time-stamping.</li>
                    <li>‘-i file’ or ‘–input-file=file’: Reads URLs from a file. In this case the file is the 1wget_####.txt file found for each directory.</li>
                </ul>
                <p>wget documentation, providing a full list of options and details can be found at <Link to={"https://www.gnu.org/software/wget/manual/"} target={"_blank"} rel={"noreferrer"}>https://www.gnu.org/software/wget/manual/</Link></p>
            </div>
        </div>
    )
}

export default BatchComp