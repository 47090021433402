import {Link} from "react-router-dom";
import ItemListComp from "../../services/colapse and expand/ItemList";
import {useEffect} from "react";


function OutIACDC () {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    const accordionData = [
        {
         name: "The Research Data Archive (RDA)",
         list: <div>
            <h2>The Research Data Archive (RDA)</h2>
             <br/>
             <Link to={"https://rda.ucar.edu/"} target={"_blank"} rel={"noreferrer"}>https://rda.ucar.edu/</Link>
             <p>Datasets:</p>
             <ul>
                 <li>Reanalysis data:
                     <ul>
                        <li>Arctic System Reanalysis 2</li>
                         <li>BPRC Arctic System Reanalysis (AST)</li>
                         <li>ECMWF 20th Century Reanalysis (ERA-20C)</li>
                         <li>ECMWF ERA15 Reanalysis (ERA15)</li>
                         <li>ECMWF ERA40 Reanalysis Project (ERA40)</li>
                         <li>ECMWF Interim Reanalysis (ERA-I)</li>
                         <li>JMA Japanese 25-year Reanalysis (JRA25)</li>
                         <li>JMA Japanese 55-year Reanalysis (JRA55)</li>
                         <li>NCAR Global Climate Four-Dimensional Data Assimilation Reanalysis (CFDDA)</li>
                         <li>NCEP Climate Forecast System Reanalysis (CFSR)</li>
                         <li>NCEP North American Regional Reanalysis (NARR)</li>
                         <li>NCEP/DOE Reanalysis II (NCEPR2)</li>
                         <li>NCEP/NCAR Reanalysis Project (NNRP)</li>
                         <li>NOAA-CIRES 20th Century Reanalysis (20CR)</li>
                     </ul>
                 </li>
                 <li>Station data:
                     <ul>
                         <li>Land surface air temperature from numerous stations</li>
                     </ul>
                 </li>
                 <li>Other:
                    <ul>
                        <li>NCAR Community Earth System Model, EaSM Project Data Set</li>
                        <li>Year of Tropical Convection (YOTC)</li>
                        <li>International Comprehensive Ocean-Atmosphere Dataset (ICOADS)</li>
                        <li>GEWEX Continental-scale International Project (GCIP)</li>
                        <li>International Surface Pressure Databank (ISPD)</li>
                    </ul>
                     <p>Please visit <Link to={"https://rda.ucar.edu/"} target={"_blank"} rel={"noreferrer"}>https://rda.ucar.edu/</Link> for more information on these, as well as additional datasets they may provide access to.</p>
                 </li>
             </ul>
         </div>
        },
        {
         name: "The Earth System Research Laboratory (ESRL) Physical Sciences Division (PSD)",
         list: <div>
                <h2>The Earth System Research Laboratory (ESRL) Physical Sciences Division (PSD)</h2>
                 <br/>
                <Link to={"https://www.esrl.noaa.gov/psd/"} target={"_blank"} rel={"noreferrer"}>https://www.esrl.noaa.gov/psd/</Link>
                <p>Datasets:</p>
                <ul>
                    <li>Gridded Climate Datasets:
                        <ul>
                            <li>CMAP Precipitation</li>
                            <li>COBE-SST</li>
                            <li>COBE-SST2 Sea Surface Temperature</li>
                            <li>CPC 0.25X0.25 Daily US Unified Precipitation, Hourly Precipitation and Soil Moisture</li>
                            <li>CRU Air Temperature and Combined Air Temperature/Marine Anomalies V3</li>
                            <li>CRU Air Temperature and Combined Air Temperature/Marine Anomalies V4</li>
                            <li>Dai Palmer Drought Severity Index</li>
                            <li>GFS Model Output</li>
                            <li>GHCN Version 3 Land Temperature Dataset</li>
                            <li>GHCN Version 2 Land Precipitation Dataset</li>
                            <li>NOAA GHCN_CAMS Land Temperature Analysis</li>
                            <li>GISS Surface Temperature Analysis</li>
                            <li>NCEP GODAS Ocean Analysis</li>
                            <li>Global Precipitation Climatology Centre (GPCC)</li>
                            <li>GPCP V2.3 Precipitation</li>
                            <li>ICOADS</li>
                            <li>Interpolated OLR</li>
                            <li>Kaplan SST</li>
                            <li>Livneh Daily Conus Near-Surface Gridded Meteorological and Derived Hydrometeorological Data</li>
                            <li>MSU</li>
                            <li>NCEP Operational Analysis</li>
                            <li>NCEP/NCAR Reanalysis</li>
                            <li>NCEP/NCAR Reanalysis Products Derived at PSD</li>
                            <li>NCEP/DOE Reanalysis II</li>
                            <li>NOAA Extended Reconstructed SST V3b</li>
                            <li>NOAA Extended Reconstructed SST V4</li>
                            <li>NOAA’s Outgoing Longwave Radiation-Daily Climate Data Record (OLR-Daily CDR): PSD Interpolated Version</li>
                            <li>NOAA High-Resolution Blended Analysis of Daily SST and Ice</li>
                            <li>NOAAGlobalTemp</li>
                            <li>NOAA Optimum Interpolated (OI) SST V2</li>
                            <li>NOAA Highly Reflective Clouds</li>
                            <li>NODC (Leviticus) World Ocean Atlas 1994 and 1998</li>
                            <li>NOAA Precipitation Reconstruction (PREC)</li>
                            <li>NOAA PREC Over Land (PREC/L)</li>
                            <li>North American Regional Reanalysis (NARR)</li>
                            <li>Northern Hemisphere EASE-Grid Snow Cover and Sea Ice Extent</li>
                            <li>NOAA-CIRES 20<sup>th</sup> Century Reanalysis V2 and V2c</li>
                            <li>of Delaware Precipitation and Air Temperature</li>
                            <li>Uninterpolated OLR</li>
                        </ul>
                    </li>
                    <li>Wind Profiling Radars</li>
                    <li>Cloud Profiling Radars</li>
                    <li>Air-Sea Fluxes</li>
                </ul>
             <p>Please visit <Link to={"https://www.esrl.noaa.gov/psd/"} target={"_blank"} rel={"noreferrer"}>https://www.esrl.noaa.gov/psd/</Link> for more information on these, as well as other datasets they may provide access to.</p>
         </div>
        },
        {
         name: "The Intergovernmental Panel on Climate Change (IPCC) Data Distribution Center",
         list: <div>
            <h2>IPCC Data Distribution Centre (DDC)</h2>
             <br/>
             <p><Link to={"http://www.ipcc-data.org/index.html"} target={"_blank"} rel={"noreferrer"}>http://www.ipcc-data.org/index.html</Link></p>
             <br/>
             <p>Data:</p>
             <ul>
                 <li>Observations</li>
                 <li>Simulations</li>
             </ul>
             <p>For more information on the datasets available through the IPCC DDC, please visit <Link to={"http://www.ipcc-data.org/index.html"} target={"_blank"} rel={"noreferrer"}>http://www.ipcc-data.org/index.html</Link>.</p>
         </div>
        },
        {
         name: "Atmospheric Science Data Center (ASDC)",
         list: <div>
            <Link to={"https://eosweb.larc.nasa.gov/"} target={"_blank"} rel={"noreferrer"}>https://eosweb.larc.nasa.gov/</Link>
         </div>
        },
        {
         name: "Goddard Earth Science Data and Information Service Center (GES DISC)",
         list: <div>
            <Link to={"https://disc.gsfc.nasa.gov/"} target={"_blank"} rel={"noreferrer"}>https://disc.gsfc.nasa.gov/</Link>
         </div>
        }
    ]
    return (
        <div>
            <div align={"left"} style={{marginLeft:"25px", marginTop:"75px", width:"55%", marginBottom:'30px'}}>
                <h2>Other Online Resources</h2>
                <br/>
                <p>There exist numerous online resources, similar to the IACDC, for the access of atmospheric and climatic data.</p>
                <br/>
                <p>A list of some of these can be found below, along with a link and information on some of the datasets accessible through them.</p>
            </div>
            <div  align={"left"} style={{marginTop:"40px", width:"55%"}}>
                 <ItemListComp items={accordionData} />
            </div>
        </div>
    )
}
export default OutIACDC