import React, {useEffect, useState} from "react";
import Utils from "../../utils/Utils";
import "../../css/button.css"
import {useNavigate} from "react-router-dom";
import ReplyForm from "./EmailReply";
import ReplyModal from "../../services/modals/ReplyModal";

const Submissions = () => {
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem("token");
    const [submits, setSubmits] = useState([])
    const [unRepliedSubmits, setUnRepliedSubmits] = useState([])
    useEffect(() => {
            console.log('Is that you? ', token);
            let subs = []
            let newSubs = []
            const getSubmits = async () => {
                let resp = await Utils.showSubmits(token)
                const suuby = resp.data
                console.log(suuby)
                // eslint-disable-next-line array-callback-return
                Object.entries(suuby).map(([key, value]) => {
                    if (value.replied === false) {
                        newSubs.push(value)
                    }
                    else {
                        subs.push(value)
                    }
                })
                // eslint-disable-next-line array-callback-return
                newSubs.sort((a, b) => {
                    if (a.time > b.time) {
                        return -1;
                    }
                })
                setUnRepliedSubmits(newSubs)
                // eslint-disable-next-line array-callback-return
                subs.sort((a, b) => {
                    if (a.time > b.time) {
                        return -1;
                    }
                })

                setSubmits(subs)
            }
            getSubmits()
    }, [token, navigate]);

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/admin_only')
    };
    const handleRowClick = (email, name, submit_id) => {
        setSelectedRow({ email, name, submit_id });
    };

        return (
            <div style={{marginBottom:'30px'}}>
                <div>
                    <h2 align={"center"}>User Form Submissions</h2>
                </div>
                <div align={'center'} style={{paddingTop: "25px", width:"90%"}}>
                    <table style={{border: "1px solid black"}}>
                        <thead>
                        <tr>
                            <th style={{padding: "5px", border: "1px solid black"}}>id</th>
                            <th style={{border: "1px solid black"}}>Time</th>
                            <th style={{border: "1px solid black"}}>name</th>
                            <th style={{border: "1px solid black"}}>email</th>
                            <th style={{border: "1px solid black", maxWidth: "150ox"}}>message</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th colSpan={5}>Unread</th>
                        </tr>
                        {
                            unRepliedSubmits.map((x, index) => {
                                return (
                                    <tr style={{border: "1px solid black", fontWeight: x.replied ? 'normal' : 'bold'}}
                                        key={index}>
                                        <td style={{border: "1px solid black", padding: "5px"}}>{x.id}</td>
                                        <td style={{padding: "5px"}}>{x.time}</td>
                                        <td style={{border: "1px solid black", padding: "5px"}}>{x.name}</td>
                                        <td style={{border: "1px solid black", padding: "5px"}} onClick={() => {
                                            handleOpenModal()
                                            handleRowClick(x.email, x.name, x.id)
                                        }}>{x.email}</td>
                                        <td style={{border: "1px solid black", padding: "5px"}}>{x.message}</td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <th colSpan={5}>Read</th>
                        </tr>
                        {
                            // eslint-disable-next-line array-callback-return
                            submits.map((x, index) => {
                                    return (
                                        <tr style={{border: "1px solid black", fontWeight: x.replied ? 'normal' : 'bold'}}
                                            key={index}>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.id}</td>
                                            <td style={{padding: "5px"}}>{x.time}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.name}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}} onClick={() => {
                                                handleOpenModal()
                                                handleRowClick(x.email, x.name, x.id)
                                            }}>{x.email}</td>
                                            <td style={{border: "1px solid black", padding: "5px"}}>{x.message}</td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                    {selectedRow && (<ReplyModal show={showModal} handleClose={handleCloseModal}>
                                            <ReplyForm handleClose={handleCloseModal} request_submit={'submit'} submit_id={selectedRow.submit_id} email={selectedRow.email} name={selectedRow.name} />
                                    </ReplyModal>
                    )}
                </div>
            </div>
        );
    }

export default Submissions