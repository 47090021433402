import {Link, useNavigate, useOutlet} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/button.css";
import LogoutModal from "../../services/modals/LogoutModal";
import Utils from "../../utils/Utils";


function DashboardComp ( ) {
    const outlet = useOutlet();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const handleShow = () => setIsModalOpen(true)
    const handleClose = () => setIsModalOpen(false)
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!token)
        {
            navigate("/")
        }
    }, [navigate, token])

const logMeOut = () => {
     setIsModalOpen(true);
     Utils.logout()
     localStorage.removeItem("user");
     localStorage.removeItem("token")
    setIsModalOpen(false);
    navigate('/');
    }

    return (
        <div style={{marginTop: "75px", marginLeft: '25px', marginBottom:'30px'}}>
            <h2>Welcome {user} to ADMIN Dashboard</h2>
            <br/>
            <button className={'button'} onClick={handleShow}>
                Logout
            </button>
            <LogoutModal show={isModalOpen} handleClose={handleClose} handleLogout={logMeOut} />
            <br/>
            <div style={{width: "600px", height: "200px", border: 1, borderColor: "black", borderRadius: "10px"}}>
                <h3>Please choose admin action:</h3>
                <div style={{display: "inline"}}>
                    <ul style={{listStyle: "none"}}>
                        <li><Link to={"/admin_only/submits"}>Show Submissions</Link></li>
                        <li><Link to={"/admin_only/db_requests"}>Show Requests</Link></li>
                        <li><Link to={"/admin_only/new_dataset"}>Add New Dataset</Link></li>
                        <li>
                            <Link to={"/admin_only/edit"} aria-disabled={true}>Edit Page Content</Link>
                            &nbsp;

                        </li>
                    </ul>
                </div>
            </div>
            {outlet}
        </div>

)
}

export default DashboardComp



