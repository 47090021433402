import React from 'react';
import { Link } from 'react-router-dom';

const processStringToHtml = (str, links) => {
    // Split string by new lines to create paragraphs
    let paragraphs = str.split('\n')

    return paragraphs.map((paragraph, pIndex) => {

        // Split paragraph into words to identify links
        const words = paragraph.split(' ');


        return (
            <p key={pIndex}>
                {words.map((word, wIndex) => {
                    // Check if the word is a link
                    if (word.startsWith('http')) {
                        return (
                            <a href={word} key={wIndex} target="_blank" rel="noopener noreferrer">
                                {word}
                            </a>
                        );
                    }
                    else if (word.startsWith('/'))
                    {
                        const title = localStorage.getItem('links')
                        console.log(JSON.parse(title)[word])
                        return (
                            <Link to={word} key={wIndex}>
                                {JSON.parse(title)[word]}
                            </Link>
                        );
                    }
                    else
                    {
                        return word + ' ';
                    }
                })}
            </p>
        );
    });
};

export default processStringToHtml ;