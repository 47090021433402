import React, {useEffect, useState} from 'react';
import Utils from "../utils/Utils";
import {useNavigate} from "react-router-dom";

const DynamicDropdowns = () => {
    const navigate = useNavigate();
    const [path, setPath] = useState([]); // Array to hold the selected path
    const [dropdowns, setDropdowns] = useState([[]]); // Array of arrays to hold dropdown options
    const token = localStorage.getItem("token");


  // Function to fetch data based on the current path
  const fetchSubdirectories = async (currentPath) => {
    // Replace with your actual fetch call
      console.log(currentPath);
    const response = await Utils.newDataset(token);
        console.log(response.data);
      return response.data; // Assume data is an array of subdirectories
  };

  useEffect(() => {
       if (!token) {
            navigate('/')
        }
        else {
            console.log(token);
           // Fetch initial data for the first dropdown
           const fetchInitialData = async () => {
               const initialData = await fetchSubdirectories([]);
               console.log(initialData);
               setDropdowns([initialData]);
           };

           fetchInitialData();
       }
  }, [navigate, token]);

  const handleChange = async (event, index) => {
    const value = event.target.value;
    const newPath = [...path.slice(0, index), value];
    console.log('path: ' + newPath.join('/'));
    setPath(newPath);

    const newData = await fetchSubdirectories(newPath);

    const newDropdowns = [...dropdowns.slice(0, index + 1), newData];
    setDropdowns(newDropdowns);
  };

  const create_symlink = (directoryName) => {
        Utils.symlink(directoryName).then(data => {
            navigate('/admin_only')
        })
        return `${directoryName} is now public!`
    }

  return (
      <div>
          <h3>Choose the directory you want to add</h3>
          {dropdowns.map((options, index) => (
                  <select style={{marginRight: '5px'}} id={'newDS'} key={index} onChange={(e) => handleChange(e, index)}>
                      <option value="">Select...</option>
                      {options.map((option, i) => (
                          <option key={i} value={option}>
                              {option}
                          </option>
                      ))}
                  </select>
              )
          )}
          <div>
              <br/>
              <button className={'button'} onClick={() => {
                  create_symlink(path.join('/'))
              }}>Create Symlink
              </button>
          </div>
      </div>
  )
      ;
};

export default DynamicDropdowns;
