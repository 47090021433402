import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import {RiArrowDropDownLine} from "react-icons/ri";
import {useState} from "react";
import "../../css/colExp.css"


function CustomToggle({ children, eventKey }) {
  const [isOpen, setIsOpen] = useState(false)
  const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
  );
  return (
      <div className={"wrapper"}>
          <div style={{width: "100%"}} key={children.cac} className={` "question-container" ${isOpen ? "active" : ""}`}
                  onClick={() => {
                      decoratedOnClick(undefined);
                      setIsOpen(!isOpen)
                  }}>
              {children}
              <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`}/>
          </div>
      </div>
  );
}
function Example(props) {
    const [activeIndex, setActiveIndex] = useState(null);
    let cac = 0
    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    }
    return (
        <Accordion defaultActiveKey='1'>
            <Card>
        <Card.Header>
          <CustomToggle eventKey="0" isOpen={activeIndex === cac + 1} onClick={() => handleItemClick(cac)}>
              {props.content.name}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>{props.content.list}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default Example