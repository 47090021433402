import React, { useState } from 'react';
import '../../css/2DivsSBS.css'
import '../../css/button.css'
import { useNavigate} from "react-router-dom";
import Utils from "../../utils/Utils";
import Modal from 'react-modal';

function ContactFormComp() {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    replied: false
  });
  const time = new Date().toLocaleString()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      time
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
      await Utils.submission(formData)
      .then((response) => {
        setIsModalOpen(true);
        console.log(JSON.stringify(formData));
        setTimeout(() => {
            setIsModalOpen(false);
          navigate('/');},8000);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      //console.log("CAPTCHA Value:", captchaValue);
  }
  return (
    <div>
      <h1 align={'center'}>Contact Us</h1>

      <form onSubmit={handleSubmit}>
        <div  style={{display:"inline-block"}}>
        <div align={"left"} style={{marginBottom:"30px"}}>
          <label style={{marginRight:"25px"}}>Name:
            &nbsp;
          <input
             style={{width:"250px"}}
            type="text"
            name="name"
            value={formData.name}
            autoComplete="name"
            onChange={handleInputChange}
            required
          />
            </label>
        </div>
        <div align={"left"} style={{marginBottom:"30px"}}>
          <label style={{marginRight:"25px"}}>Email:
            &nbsp;
          <input
            style={{width:"250px"}}
            type="email"
            name="email"
            value={formData.email}
            autoComplete="email"
            onChange={handleInputChange}
            required
          />
            </label>
        </div>
        <div align={"left"} style={{marginBottom:"30px"}}>
          <label style={{marginBottom:"20px"}}><strong>Message:</strong>
          <textarea style={{width:"450px", height:"200px"}}
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            required
          ></textarea>
            </label>
            <button className={'button'} type="submit">Submit</button>
          </div>
        </div>
        <div style={{display:"none"}}>
        </div>
      </form>
      <Modal
          style={{
            overlay: {
              position: 'fixed',
              top: "75px",
              left: "25%",
              right: "25%",
              bottom: "25%",
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              borderRadius: '10px'
            },
            content: {
              position: 'absolute',
              top: '40px',
              left: '40px',
              right: '40px',
              bottom: '40px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px'
            }
            }}
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Form Submitted"
      >
          <h2>Form Submitted</h2>
          <p>Your form has been submitted successfully.</p>
          <button className={'button'} onClick={() => {
            setIsModalOpen(false);
            navigate('/')
          }}>Close</button>
      </Modal>
    </div>
  );
}

export default ContactFormComp;


