import {Link} from "react-router-dom";
import {useEffect} from "react";

function DataRequestComp() {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    return (
        <div>
             <div align={"left"} className={"flex-child-element:first-child"} style={{float:"right", width:"27%", marginTop:"60px", marginRight:"35px"}}>
                <h4>Data Request Forms are Available for the Following Datasets</h4>
                 <br/>
                 <div>
                        {
                            <div align={"left"} style={{marginBottom:"10px"}}><Link to={'/data-requests/request'}>Submit dataset request</Link></div>
                        }
                    </div>
            </div>
            <div className={"flex-child-element"} align={"left"} style={{marginTop:"75px", marginLeft:"25px", width:"55%", marginBottom:'30px'}}>
                <h2>Data Requests from the IACDC</h2>
                <div>
                    <p><br/><br/>At the IACDC we aim to provide researchers with easy access to a large number of datasets, we are therefore happy to offer researchers the ability to “order” data they require from the center, and have it temporarily stored with us for their download.</p>
                    <br/>
                    <h2>Data Requests from the IACDC</h2>
                    <br/><br/>
                    <p>Data requests, for a range of datasets, are available through order forms. Once you have submitted your order, processing of your request will begin, should we require additional information in order to fulfil your order we shall let you know.</p>
                    <p>Should an order form for the dataset you require not be available please get in contact <Link to={"/contact"}>here</Link> page with a description of the data you would like including: dataset, fields, time frequency, resolution and time span. Please note that only data for which order forms are available can be guaranteed, however we will do our best to fulfil or</p>
                    <p>Once your request is completed we will contact you with information on how to access your order. Due to limited storage space, ordered data will be stored with us for two weeks.</p>
                    <p>If you placed a data order from the IACDC, we will notify you once it is ready for collection in our <Link to={"/data-requests/:subs"}>order collections</Link></p>
                </div>

            </div>
        </div>
    )
}
export default DataRequestComp