import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function AboutComp() {
    const [alignment, setAlignment] = useState("left");

    const aboutUs = "To relieve researchers of part of the time and effort associated with finding" +
        "and accessing geophysical datasets from projects across the globe."
    const aboutUs2 = "To collect and provide easy access to data unique to Israel," +
        "such as output from regional models, historical reports and other measurements."
    useEffect(() => {
        setAlignment("left")
        window.scrollTo(0, 0)
    }, []);
    return (
        <div >
             <div style={{marginTop:"75px", marginBottom:'30px'}}>
                 <div align={alignment} className={"flex-child-element:first-child"} style={{float:"right", width:"27%", marginTop:"40px", marginRight:"35px"}}>
                    <p>We are happy to host data sets unique to the Mediterranean and the Middle East, including data
                        sets used for climate impact studies.
                        If you are interested in making your data available through our center
                        please contact us <Link to={"/contact"}>here</Link> or by emailing <a href={"mailto:contact@iacdc.tau.ac.il"}>contact@iacdc.tau.ac.il</a>. </p>
                </div>
                  <div align={alignment} className={"flex-child-element"} style={{padding:"5px", width:"60%", marginLeft:"25px"}}>
                      <h2 align={"center"}>IACDC:</h2>
                      <br/>
                      <h3 align={alignment}>About Us</h3>
                      <br/>
                      <ul><h6>Funded by the Israeli Ministry of Science, Technology and Space, the IACDC
                          (Isareli Atmospheric Climatic Data Center) aims to:</h6>
                          <li >{aboutUs}</li>
                          <li>{aboutUs2}</li>
                    </ul>
                </div>

             </div>
            <div style={{width:"63%"}}>
                <div style={{padding:"15px", width:"95%", borderTop : "solid 2px black", margin:"10px"}}>
                    <h3 align={alignment}>What we Do</h3>
                    <br/>
                    <p>At the IACDC we aim to facilitate the access and use of a multitude of datasets through the standardized
                        archiving of geophysical data. Various datasets, such as climate model output,
                        reanalyses, satellite data, and raw measurements, will be archived using the Obs4MIPS and CMIP5 archiving standard;
                        thereby relieving researchers of much of the work associated with downloading and converting between data formats,
                        as well as allowing them to employ uniform analysis methods across datasets.</p>
                    <p>We also dedicate a special effort to collect data unique to Israel such as output from regional models,
                        historical reports, and other measurements, unavailable elsewhere.</p>
                    <p>We are also happy to offer researchers the ability to “order” data they require from the center, and have it temporarily
                        stored with us. And additionally aid in creating a “community data sharing” model, connecting between researchers with
                        access to different datasets, to maximise the volume of data accessible to researchers in the field.</p>
                    <p>The center is currently in the process of being rebuilt. We currently have access to numerous datasets in our data access page
                        and are able to download other datasets or variables upon request. At a later stage we also aim is to provide the ability
                        to perform additional tasks, such as subsetting and calculation of derived quantities upon request.</p>
                </div>
                <div style={{padding: "15px", width: "95%", borderTop: "solid 2px black", margin: "10px"}}>
                    <h4 align={alignment}>The People Behind the IACDC</h4>
                    <div align={alignment}>
                        <h6>Heads:</h6>
                        <a href={"https://english.tau.ac.il/profile/pinhas"} target={"_blank"} rel={"noreferrer"}>Prof.
                            Pinhas Alpert </a><br/>
                        <a href={"https://en-exact-sciences.tau.ac.il/profile/harnik"} target={"_blank"}
                           rel={"noreferrer"}>Prof. Nili Harnik </a>
                    </div>
                    <br/>
                    <div align={alignment}>
                        <h6>When established, at ~ 2010, IACDC had as a Manager Maaian Rotstein,</h6>
                        <h6>And a steering committee including Dr. Dorita Rostkier-Edelstein, Dr. Ori Adam , Prof.
                            Yossi Ashkenazy and Prof. David Broday</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutComp
