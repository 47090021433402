import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Utils from "../../utils/Utils";

function CategoriesComp () {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        const dataCats = []
        window.scrollTo(0, 0)
       /* const getCategories = async () => {
            const resp = await Utils.getAllDirs()
            const data = resp.data
            console.log(resp.data)
            // eslint-disable-next-line array-callback-return
            data.map(d => {
                dataCats.push(d)
            })
            setCategories(dataCats)
            console.log(dataCats)
        }
        getCategories()*/
    }, [])
    return (
        <div>
             <div className={"flex-child-element:first-child"} style={{float:"right", width:"27%", marginTop:"75px", marginRight:"35px"}}>
                <h4>Please choose from the categories below:</h4>
                 <div style={{marginTop:"40px"}}>
                   <div>

                    </div>
                 </div>
             </div>
             <div align={"left"} className={"flex-child-element"} style={{width:"55%", marginTop:"75px", marginLeft:"25px"}}><h2>Browse Datasets by Category</h2>
             <br/>
                <p>The IACDC has sorted datasets into categories to facilitate researchers to find data
                in their area of research. Here you will find information as well as links to data acccess,
                at the IACDC when available, for various categories including <Link to={"hydrological-cycle"}>rainfall </Link>
                and temperature observations,
                climate model output, satellite data, and more. Just choose your category of interest
                to find relevant datasets.</p>
             </div>
        </div>
    )
}

export default CategoriesComp